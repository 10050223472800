import {
    BaseHeaderValueCard,
    HeaderValueCardName,
    HeaderValueCardSubtitle,
    HeaderValueCheckboxContainer,
    HeaderValueIcon,
    HeaderValueInput
} from './HeaderValueComponents.css';
import React, { FC } from 'react';
import { DialogCheckbox, DialogDropdownSingle, DialogFileField, DialogTextField } from '../../common/Dialog/GenericDialog';
import SVGInline from 'react-inlinesvg';
import icons from '../../../style';
import { AudienceVersionTypes } from '../../../types/Audience';
import { IconButton, InputAdornment } from '@material-ui/core';
import getTheme from '../../../style/themes/theme';
import { StyledText } from '../../Languages/Dialogs/NewLanguage.css';
import { validateVersionFormat } from '../../../utils/fnString';
import { renderTooltip, tooltipTypes } from '../../common/Tooltips/Tooltips';
import { audiencesIcons } from '../../../assets/images/icons/audiencesIcons';

const theme: any = getTheme();

export const DeviceClassCard: FC<{ deviceClass: string; onCheck: () => void; checked: boolean }> = ({ deviceClass, onCheck, checked }) => {
    return (
        <BaseHeaderValueCard $checked={checked} onClick={() => onCheck()}>
            <HeaderValueIcon $backgroundColor={'red'}>
                <SVGInline src={audiencesIcons.deviceClass[deviceClass] || icons.valueIcon} />
            </HeaderValueIcon>
            <div>
                <HeaderValueCardName>{deviceClass}</HeaderValueCardName>
                <HeaderValueCardSubtitle>{`x-device-class`}</HeaderValueCardSubtitle>
            </div>
            <HeaderValueCheckboxContainer>
                <DialogCheckbox
                    onClick={() => onCheck()}
                    text={''}
                    value={checked}
                    active={checked}
                    dataCy={'audience-device-class-card'}
                />
            </HeaderValueCheckboxContainer>
        </BaseHeaderValueCard>
    );
};

export const DeviceTypeCard: FC<{ deviceType: string; onCheck: () => void; checked: boolean }> = ({ deviceType, onCheck, checked }) => {
    return (
        <BaseHeaderValueCard $checked={checked} onClick={() => onCheck()}>
            <HeaderValueIcon $backgroundColor={theme.palette.success.main}>
                <SVGInline src={audiencesIcons.deviceType[deviceType] || icons.valueIcon} />
            </HeaderValueIcon>
            <div>
                <HeaderValueCardName>{deviceType}</HeaderValueCardName>
                <HeaderValueCardSubtitle>{`x-device-type`}</HeaderValueCardSubtitle>
            </div>
            <HeaderValueCheckboxContainer>
                <DialogCheckbox onClick={() => onCheck()} text={''} value={checked} active={checked} />
            </HeaderValueCheckboxContainer>
        </BaseHeaderValueCard>
    );
};

export const OSTypeCard: FC<{ osType: string; onCheck: () => void; checked: boolean }> = ({ osType, onCheck, checked }) => {
    return (
        <BaseHeaderValueCard $checked={checked} onClick={() => onCheck()}>
            <HeaderValueIcon $backgroundColor={theme.palette.warning.main}>
                <SVGInline src={audiencesIcons.operatingSystem[osType] || icons.valueIcon} />
            </HeaderValueIcon>
            <div>
                <HeaderValueCardName>{osType}</HeaderValueCardName>
                <HeaderValueCardSubtitle>{`x-operating-system`}</HeaderValueCardSubtitle>
            </div>
            <HeaderValueCheckboxContainer>
                <DialogCheckbox onClick={() => onCheck()} text={''} value={checked} active={checked} />
            </HeaderValueCheckboxContainer>
        </BaseHeaderValueCard>
    );
};

export const SingleInputCard: FC<{
    inputValue: string;
    onChange: (param: string) => void;
    checked: boolean;
    onCheck: () => void;
    subtitle?: string;
    infoText?: string;
    customIcon?: string;
    error?: string;
}> = ({ inputValue, onChange, checked, onCheck, subtitle, infoText, customIcon, error }) => {
    return (
        <BaseHeaderValueCard onMouseDown={() => onCheck()} $checked={checked}>
            <HeaderValueIcon $backgroundColor={`#EC6240`}>
                <SVGInline src={customIcon || icons.valueIcon} />
            </HeaderValueIcon>
            <div>
                <HeaderValueInput
                    onClick={(evt) => checked && evt.stopPropagation()}
                    onMouseDown={(evt) => checked && evt.stopPropagation()}
                    $error={!!error}
                >
                    <DialogTextField
                        onChange={(evt: any) => {
                            onChange(evt.target.value);
                        }}
                        value={checked ? inputValue : ''}
                        placeholder={'Add value'}
                        isDisabled={!checked}
                        toolTipText={infoText}
                        optional={!!infoText}
                        noError
                        noLabel
                    />
                </HeaderValueInput>
                {error ? (
                    renderTooltip(<HeaderValueCardSubtitle $error>{subtitle}</HeaderValueCardSubtitle>, tooltipTypes.TEXT, subtitle || '')
                ) : (
                    <HeaderValueCardSubtitle>{subtitle}</HeaderValueCardSubtitle>
                )}
            </div>
            <HeaderValueCheckboxContainer>
                <DialogCheckbox onClick={() => onCheck()} text={''} value={checked} active={checked} />
            </HeaderValueCheckboxContainer>
        </BaseHeaderValueCard>
    );
};

export const RangeInputCard: FC<{
    inputValue: string;
    range: AudienceVersionTypes | null;
    onInputChange: (param: string) => void;
    onRangeChange: (param: AudienceVersionTypes | null) => void;
    checked: boolean;
    onCheck: () => void;
    subtitle?: string;
    onSecondInputChange?: (param: string) => void;
    secondInputValue?: string;
    selectUp?: boolean;
    error?: string;
    customIcon?: string;
}> = ({
    inputValue,
    range,
    onInputChange,
    onRangeChange,
    subtitle,
    checked,
    onCheck,
    secondInputValue,
    onSecondInputChange,
    selectUp,
    error,
    customIcon
}) => {
    const rangeOptions = [
        {
            value: AudienceVersionTypes.LOWER_THAN,
            label: '< x.x.x.x'
        },
        {
            value: AudienceVersionTypes.GREATER_THAN,
            label: '> x.x.x.x'
        },
        {
            value: AudienceVersionTypes.IN_BETWEEN,
            label: 'x.x.x.x - x.x.x.x'
        }
    ];

    return (
        <BaseHeaderValueCard onClick={() => onCheck()} $checked={checked}>
            <HeaderValueIcon $backgroundColor={`#107C9E`}>
                <SVGInline src={customIcon || icons.rangeIcon} />
            </HeaderValueIcon>
            <div>
                <HeaderValueInput $twoInputs={range === AudienceVersionTypes.IN_BETWEEN} $error={!!error}>
                    {range ? (
                        range === AudienceVersionTypes.IN_BETWEEN ? (
                            <>
                                <DialogTextField
                                    onClick={(e: any) => e?.stopPropagation()}
                                    onChange={(evt: any) => {
                                        const newVersion = evt.target.value;
                                        if (newVersion.slice(-1) === '.' && newVersion.slice(-2)[0] !== '.') onInputChange(newVersion);
                                        else if (validateVersionFormat(newVersion)) onInputChange(newVersion);
                                        else if (!newVersion) onInputChange(newVersion);
                                    }}
                                    value={inputValue}
                                    placeholder={'x.x.x.x'}
                                    noError
                                    noLabel
                                />
                                <div>-</div>
                                <DialogTextField
                                    onClick={(e: any) => e?.stopPropagation()}
                                    onChange={(evt: any) => {
                                        if (!onSecondInputChange) return;
                                        const newVersion = evt.target.value;
                                        if (newVersion.slice(-1) === '.' && newVersion.slice(-2)[0] !== '.')
                                            onSecondInputChange(newVersion);
                                        else if (validateVersionFormat(newVersion)) onSecondInputChange(newVersion);
                                        else if (!newVersion) onSecondInputChange(newVersion);
                                    }}
                                    value={secondInputValue || ''}
                                    placeholder={'x.x.x.x'}
                                    noError
                                    noLabel
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => {
                                                        onRangeChange(null);
                                                        onInputChange?.('');
                                                        onSecondInputChange?.('');
                                                    }}
                                                >
                                                    <SVGInline src={icons.closeIcon} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </>
                        ) : (
                            <DialogTextField
                                onClick={(e) => e?.stopPropagation()}
                                onChange={(evt: any) => {
                                    const newVersion = evt.target.value;
                                    if (newVersion.slice(-1) === '.' && newVersion.slice(-2)[0] !== '.') onInputChange(newVersion);
                                    else if (validateVersionFormat(newVersion)) onInputChange(newVersion);
                                    else if (!newVersion) onInputChange(newVersion);
                                }}
                                noError
                                noLabel
                                value={inputValue}
                                placeholder={rangeOptions.find((opt) => opt.value === range)?.label?.slice(1) || ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    onRangeChange(null);
                                                    onInputChange('');
                                                }}
                                            >
                                                <SVGInline src={icons.closeIcon} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton>
                                                <SVGInline
                                                    style={range === AudienceVersionTypes.LOWER_THAN ? { transform: 'rotate(180deg)' } : {}}
                                                    src={icons.arrowRightIcon}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )
                    ) : (
                        <DialogDropdownSingle
                            onChange={(newVal: any) => onRangeChange(newVal.value)}
                            placeholder={'Select range'}
                            options={rangeOptions}
                            value={range}
                            isDisabled={!checked}
                            noLabel
                            noError
                            up={selectUp}
                            onClick={(e: any) => {
                                e?.stopPropagation();
                            }}
                        />
                    )}
                </HeaderValueInput>
                {error ? (
                    renderTooltip(<HeaderValueCardSubtitle $error>{subtitle}</HeaderValueCardSubtitle>, tooltipTypes.TEXT, subtitle || '')
                ) : (
                    <HeaderValueCardSubtitle>{subtitle}</HeaderValueCardSubtitle>
                )}
            </div>
            <HeaderValueCheckboxContainer>
                <DialogCheckbox onClick={() => onCheck()} text={''} value={checked} active={checked} />
            </HeaderValueCheckboxContainer>
        </BaseHeaderValueCard>
    );
};

export const FileInputCard: FC<{
    checked: boolean;
    onCheck: () => void;
    onFileChange: (file: File) => void;
    subtitle?: string;
    infoText?: string;
    customIcon?: string;
    error?: string;
}> = ({ checked, onCheck, subtitle, customIcon, onFileChange, error }) => {
    return (
        <BaseHeaderValueCard onClick={() => onCheck()} $checked={checked}>
            <HeaderValueIcon $backgroundColor={`#EC6240`}>
                <SVGInline src={customIcon || icons.valueIcon} />
            </HeaderValueIcon>
            <div>
                <HeaderValueInput onClick={(evt) => checked && evt.stopPropagation()} $error={!!error}>
                    <DialogFileField
                        accepts={['.csv']}
                        hideAccepted
                        fieldKey=""
                        additionalText={<StyledText>Add .csv file</StyledText>}
                        localFileCallBack={(value) => onFileChange(value)}
                        localFileOnly
                        customLabel={''}
                    />
                </HeaderValueInput>
                {error ? (
                    renderTooltip(<HeaderValueCardSubtitle $error>{subtitle}</HeaderValueCardSubtitle>, tooltipTypes.TEXT, subtitle || '')
                ) : (
                    <HeaderValueCardSubtitle>{subtitle}</HeaderValueCardSubtitle>
                )}
            </div>
            <HeaderValueCheckboxContainer>
                <DialogCheckbox onClick={() => onCheck()} text={''} value={checked} active={checked} />
            </HeaderValueCheckboxContainer>
        </BaseHeaderValueCard>
    );
};
